import './App.css';

import computer from './computer.svg';
import rocket from './rocket.png';
import viajy from './viajy.png';

function App() {
  return (
    <div className="page">
      <div className="container">
        <img className="image-rocket" src={rocket} alt="Rocket" />
        <img className="image-viajy-mobile" src={viajy} alt="Logo" />
        <img className="image-computer" src={computer} alt="Viajy" />
        <div className="description">
          <img className="image-viajy" src={viajy} alt="Logo" />
          <p className="message">
            El aliado de las agencias de viaje, para que lo operativo sea automático y de los viajeros para hacer sus viajes inolvidables.
          </p>
          <p>
            ¡Próximamente!
          </p>
        </div>
      </div>
      <div className="footer"></div>
    </div>
  );
}

export default App;
